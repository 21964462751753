export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    //padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        CartItemHandler: {
            cursor: 'pointer',
            '&:hover > *': {
                color: `${theme.colors?.primaryDark} !important`
            },
            '& :first-child': {
                padding: 0,
                paddingRight: '7px',
                borderRadius: '1px',
                textTransform: 'capitalize',
                '& .MuiSvgIcon-root': {
                    color: 'primary',
                    fontWeight: 900,
                    fontSize: '1.3rem'
                }
            }
        },
        CartItemValueChip: {
            color: '#2196f3',
            border: '#2196f3 solid',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '20px',
            marginTop: '5px',
            marginRight: '10px',
            alignSelf: 'flex-end',
            textTransform: 'capitalize',
            '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
                padding: '0.2rem 0.5rem'
            }
        },
        Tabs: {
            '.MuiTab-root': { borderRadius: 3 },
            //borderRight: 1,
            //borderColor: 'divider',
            '.Mui-selected': {
                background: theme.colors?.secondaryLight,
                color: `${theme.colors.secondaryDark} !important`
            },
            '& * > .MuiTabs-indicator': { background: 'unset' }
        },
        TabsCheckout: {
            '.MuiTab-root': { px: 2, borderRadius: 3, justifyContent: 'flex-start' },
            '.MuiTab-root[tabindex="-1"]:not([disabled=""])': { color: `rgb(0, 200, 83) !important` }
        },
        DeleteCart: {
            padding: 0,
            paddingRight: '7px',
            borderRadius: '1px',
            textTransform: 'capitalize',
            '& .MuiSvgIcon-root': {
                color: 'primary',
                fontSize: '1.3rem',
                fontWeight: 900
            }
        },
        Favorite: {
            position: 'absolute',
            right: '0',
            top: '-1rem',
            margin: '0.4rem',
            padding: '0.4rem',
            borderRadius: '4px',
            textTransform: 'capitalize',
            '& .MuiSvgIcon-root': {
                color: 'primary'
            }
        },
        ServiceLabel: {
            'Entrega Imediata': {
                color: 'rgb(0, 200, 83)',
                backgroundColor: 'rgba(185, 246, 202, 0.376)',
                borderRadius: '4px',
                textTransform: 'capitalize',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '0.2rem 0.5rem'
                }
            },
            Novo: {
                color: '#2196f3',
                backgroundColor: '#eef2f6',
                borderRadius: '4px',
                textTransform: 'capitalize',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '0.2rem 0.5rem'
                }
            }
        },
        Modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '500px'
        },
        ServiceImage: { height: 500, backgroundSize: 'contain', p: 5 },
        ErrorInputLabel: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
    };
}
