import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    IconButton,
    InputAdornment,
    Typography,
    useMediaQuery
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { ForgotPassword, ResetPassword } from 'services/auth.service';

import { strengthColor, strengthIndicator } from 'utils/password-strength';
//import PinInput from 'ui-component/PinInput';
import { PinInput, PinInputField } from '@chakra-ui/react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClientService from 'services/client.service';

const ClientSv = new ClientService();

const AuthForgotPassword = ({ setMode, ...others }) => {
    const theme = useTheme();
    const [data, setData] = useState(false);
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [pinForm, setPinForm] = useState({});
    const [verifiedPin, setVerifiedPin] = useState(false);
    const [pinErrors, setPinErrors] = useState(null);
    const [tokens, setTokens] = useState({});
    const [success, setSuccess] = useState(false);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            const { data } = await ForgotPassword(values);
            setStatus({ success: true });
            setSubmitting(false);
            setPinForm({ email: values.email });
            setData(data.message);
        } catch (err) {
            process.env.NODE_ENV !== 'production' && console.error(err);

            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
        }
    };

    const onSubmitResetPassword = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            sessionStorage.setItem('accessToken', tokens.accessToken);
            sessionStorage.setItem('refreshToken', tokens.refreshToken);
            await ClientSv.changePassword(values);
            setStatus({ success: true });
            setSubmitting(false);
            setPinForm({ email: values.email });
            setData('Senha redefinida com sucesso! Realize o login com sua nova senha');

            setSuccess(true);
            setTimeout(() => {
                setMode('login');
            }, 6000);
        } catch (err) {
            process.env.NODE_ENV !== 'production' && console.log(err);
            process.env.NODE_ENV !== 'production' && console.error(err);

            setStatus({ success: false });
            setErrors({ submit: err?.message || err });
            setSubmitting(false);
        } finally {
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const onPinSubmit = async (pin) => {
        try {
            const values = { ...pinForm, pin };
            process.env.NODE_ENV !== 'production' && console.log(values, 'pin');
            const { data } = await ResetPassword(values);
            setTokens(data);
            setData(data.message);
            setVerifiedPin(true);
        } catch (err) {
            process.env.NODE_ENV !== 'production' && console.error(err);
            setPinErrors(err);
            //setStatus({ success: false });
            //setErrors({ submit: err });
            //setSubmitting(false);
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 1 }} alignItems="center">
                        <Typography variant="subtitle1" textAlign={'center'}>
                            Recuperação de conta
                        </Typography>
                    </Box>
                </Grid>
                {!data ? (
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">
                            Para ajudar a proteger sua conta, a BRDocs quer confirmar se é realmente você que está tentando fazer login
                        </Typography>
                        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                            Confirme o endereço de e-mail cadastrado
                        </Typography>{' '}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center" sx={{ mb: 1 }}>
                            {data}
                        </Typography>

                        {!verifiedPin && (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                spacing={2}
                                sx={{ width: '100%', position: 'relative', height: '40px', mt: 1, mb: 1 }}
                            >
                                <PinInput size="lg" focusBorderColor={'#ffbc2e'} onComplete={onPinSubmit} name="pin">
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                    <PinInputField maxW={'40px'} textAlign={'center'} />
                                </PinInput>
                            </Stack>
                        )}
                        {!verifiedPin && pinErrors && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {pinErrors}
                            </FormHelperText>
                        )}
                        {verifiedPin && !success && (
                            <Formik
                                initialValues={{
                                    senha: '',
                                    submit: null
                                }}
                                validationSchema={Yup.object().shape({
                                    senha: Yup.string().max(255).required('Senha é um campo obrigatório')
                                })}
                                onSubmit={onSubmitResetPassword}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit} {...others}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.password && errors.password)}
                                            sx={{ ...theme.typography.customInput }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-password-register">Nova Senha</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password-register"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.senha}
                                                name="senha"
                                                label="Nova Senha"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    changePassword(e.target.value);
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                inputProps={{}}
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="standard-weight-helper-text-password-register">
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </FormControl>

                                        {strength !== 0 && (
                                            <FormControl fullWidth>
                                                <Box sx={{ mb: 2 }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item>
                                                            <Box
                                                                style={{ backgroundColor: level?.color }}
                                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                                {level?.label === 'Weak'
                                                                    ? 'Fraca'
                                                                    : level?.label === 'Good'
                                                                    ? 'Boa'
                                                                    : level?.label === 'Strong'
                                                                    ? 'Forte'
                                                                    : 'Normal'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </FormControl>
                                        )}
                                        {errors.submit && (
                                            <Box sx={{ mt: 3 }}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}

                                        <Box sx={{ mt: 2 }}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Redefinir Senha
                                                </Button>
                                            </AnimateButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        )}

                        {!verifiedPin && !success && (
                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={!verifiedPin}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Continuar
                                    </Button>
                                </AnimateButton>
                            </Box>
                        )}
                    </Grid>
                )}
            </Grid>

            {!data && (
                <Formik
                    initialValues={{
                        email: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Deve ser um e-mail válido').max(255).required('E-mail é um campo obrigatório')
                    })}
                    onSubmit={onSubmit}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others}>
                            <Grid container spacing={matchDownSM ? 0 : 2}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-email-register">E-mail</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="email"
                                            value={values.email}
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Continuar
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default AuthForgotPassword;
