import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import cartReducer from './cartReducer';
import loginReducer from './loginReducer';

const reducer = combineReducers({
    customization: customizationReducer,
    cart: cartReducer,
    login: loginReducer
});

export default reducer;
