import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Card,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Popper,
    Select,
    Stack
} from '@mui/material';

import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

import Transitions from 'ui-component/extended/Transitions';

import { IconAdjustmentsHorizontal, IconFileText, IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import ServicesSv from 'services/services.service';
import { List, ListItem, ListItemIcon, ListItemText, Icon } from '@mui/material';

import { Link } from 'react-router-dom';
import { useRef } from 'react';
import getCategoryIconByURL from 'utils/categoriesIcons';
import { ArrowDropDownTwoTone } from '@mui/icons-material';
import { removeAccents } from 'utils/utils';

const SearchResults = ({ setListFocused, filteredServices, width, marginLeft, marginRight, top, filteredCat }) => {
    const filteredSvs = filteredServices.map((service) => {
        const CategoryIcon = getCategoryIconByURL(service.categoryURL);
        
        return (
            (service.categoria === filteredCat || filteredCat === 'Todas') && (
                <ListItem
                    key={service.id}
                    button
                    component={Link}
                    to={`/servicos/${service.url}`}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#eef2f6'
                        }
                    }}
                >
                    <ListItemIcon>
                        <CategoryIcon size={18} />
                    </ListItemIcon>
                    <ListItemText primary={service.nome} />
                </ListItem>
            )
        );
    });
    if (filteredSvs.length === 0) return null;
    return (
        <List
            onFocus={() => setListFocused(true)}
            onBlur={() => setListFocused(false)}
            style={{
                position: 'absolute',
                zIndex: 15,
                width,
                marginLeft,
                marginRight,
                top,
                maxHeight: '200px',
                overflow: 'auto',
                //borderRadius: '0 0 12px 12px',
                backgroundColor: 'white',
                borderStyle: 'solid',
                borderWidth: '0px 1px 1px 1px',
                borderColor: 'rgba(0, 0, 0, 0.23)'
            }}
        >
            {filteredSvs}
        </List>
    );
};

// Certifique-se de que filteredServices é um array
SearchResults.propTypes = {
    filteredServices: PropTypes.array.isRequired
};

const Services = new ServicesSv();
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '100%',
    top: '-40px !important',
    padding: 0
    //padding: '12px 0',
    //[theme.breakpoints.down('sm')]: {
    //    padding: '0 10px'
    //}
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    //width: 800,
    marginLeft: 0,
    paddingLeft: 16,
    paddingRight: 16,

    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 220,
        background: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100vw',
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

const MobileSearch = ({ onFocus, onBlur, inputRef, value, handleChange, popupState }) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            id="input-search-header"
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            onChange={handleChange}
            placeholder="O que você procura?"
            sx={{
                '& fieldset': { borderRadius: 0 },
                '& .MuiPaper-root': {
                    borderRadius: 0
                }
            }}
            componentsProps={{
                sx: { borderRadius: 0 },
                '& .MuiPaper-root': {
                    borderRadius: 0
                }
            }}
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {/*<ButtonBase sx={{ borderRadius: '12px' }}>
                        <HeaderAvatarStyle variant="rounded">
                            <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                        </HeaderAvatarStyle>
                    </ButtonBase>*/}
                    <Box sx={{ ml: 2 }}>
                        <ButtonBase
                            sx={
                                {
                                    //borderRadius: '12px'
                                }
                            }
                        >
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    background: theme.palette.orange.light,
                                    color: theme.palette.orange.dark,
                                    '&:hover': {
                                        background: theme.palette.orange.dark,
                                        color: theme.palette.orange.light
                                    }
                                }}
                                {...bindToggle(popupState)}
                            >
                                <IconX stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    ref: PropTypes.any,
    setValue: PropTypes.func,
    popupState: PopupState
};

const SearchSection = () => {
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const theme = useTheme();
    const [value, setValue] = useState('');
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredCat, setFilteredCat] = useState('Todas');
    const [filteredSv, setFilteredSv] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [listFocused, setListFocused] = useState(false);

    const handleFocus = () => {
        setShowResults(true);
    };

    const handleBlur = () => {
        //!listFocused && setShowResults(false);
        setTimeout(() => {
            setShowResults(false);
        }, 300);
    };

    const marginLeft = 0;
    const marginRight = 0;

    const getServices = async () => {
        try {
            const data = await Services.getServicesData();
            const catData = await Services.getCategories();
            setCategories(catData);
            setServices(data);
        } catch (err) {
            process.env.NODE_ENV !== 'production' && console.log(err);
        }
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        const filterText = removeAccents(e.target.value.toLowerCase());
        const filteredServices = services.filter((service) => removeAccents(service.nome.toLowerCase()).includes(filterText)).slice(0, 5);
        setFilteredSv(filteredServices);
    };

    useEffect(() => {
        getServices();
    }, []);
    useEffect(() => {
        process.env.NODE_ENV !== 'production' && console.log(inputRef);
    }, [inputRef]);
    return (
        <>
            <Box
                sx={{
                    display: { xs: 'flex', sm: 'none' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                    position: 'relative'
                }}
            >
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box>
                                <ButtonBase sx={{ borderRadius: '12px', pr: 1 }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconSearch stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    borderRadius: 0,
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 0 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch
                                                                onFocus={handleFocus}
                                                                onBlur={handleBlur}
                                                                inputRef={inputRef2}
                                                                value={value}
                                                                handleChange={handleChange}
                                                                popupState={popupState}
                                                            />
                                                            {showResults && value && (
                                                                <SearchResults
                                                                    width={
                                                                        inputRef2.current?.clientWidth //- 20
                                                                    }
                                                                    marginLeft={0}
                                                                    marginRight={0}
                                                                    filteredServices={filteredSv}
                                                                    filteredCat={filteredCat}
                                                                    top={inputRef2.current?.clientHeight}
                                                                    setListFocused={setListFocused}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Stack
                    direction="row"
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: 0
                        },
                        ml: 0,
                        mb: 0.5
                    }}
                >
                    <Select
                        sx={{
                            borderRadius: 0,
                            //borderRadius: '12px 0 0 12px',
                            '& .MuiPaper': {
                                borderRadius: 0
                            },
                            '& fieldset': {
                                borderRadius: 0,
                                //borderRadius: '12px 0 0 12px !important',
                                borderWidth: '1px 0.1px 1px 1px'
                            },
                            maxWidth: '100px'
                        }}
                        MenuProps={{
                            sx: {
                                borderRadius: 0,
                                '& .MuiPaper-root': {
                                    borderRadius: 0
                                }
                            }
                        }}
                        value={filteredCat}
                        onChange={(e) => setFilteredCat(e.target.value)}
                        name={'categoryFilter'}
                        fullWidth
                    >
                        <MenuItem value="Todas">Todas</MenuItem>
                        {categories.map((category) => (
                            <MenuItem value={category.nome} key={category.id}>
                                {category.shortName}
                            </MenuItem>
                        ))}
                    </Select>
                    <Box sx={{ position: 'relative' }}>
                        <OutlineInputStyle
                            ref={inputRef}
                            id="input-search-header"
                            value={value}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder="O que você procura?"
                            sx={{
                                //borderRadius: '0 12px 12px 0px',
                                '& fieldset': {
                                    borderRadius: 0
                                    //borderRadius: '0 12px 12px 0 !important'
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                </InputAdornment>
                            }
                            aria-describedby="search-helper-text"
                            inputProps={{ 'aria-label': 'weight' }}
                        />
                        {showResults && value && (
                            <SearchResults
                                width={
                                    inputRef.current?.clientWidth //- 10
                                }
                                marginLeft={marginLeft}
                                marginRight={marginRight}
                                filteredServices={filteredSv}
                                filteredCat={filteredCat}
                                top={inputRef.current?.clientHeight}
                                setListFocused={setListFocused}
                            />
                        )}
                    </Box>
                </Stack>
            </Box>
        </>
    );
};

export default SearchSection;
