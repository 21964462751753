import { styled } from '@mui/material/styles';
import BrDocsLoader from 'ui-component/BrDocsLoader';
// styles
const LoaderWrapper = styled('div')({
    zIndex: 1301,
    width: '100%',
    display: 'block',
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '100vh'
});

const Loader = (props) => (
    <LoaderWrapper>
        <BrDocsLoader {...props} />
    </LoaderWrapper>
);

export default Loader;
