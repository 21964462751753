import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import Flag from 'react-world-flags';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

const LanguageSection = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <Stack px={3} direction="row" height="100%" width="80">
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    width: '80px',
                    height: '1.4375em',
                    padding: '25px',
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                    }
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
            >
                {/*<Flag code="US" height="16" width="auto" />*/}
                <Flag code="BR" height="16" width="auto" />
                <Typography px={1}>BR</Typography>
            </Avatar>
        </Stack>
    );
};

export default LanguageSection;
