import ObjectUtil from '../utils/object-util';
import customAxios from './middleware';
import ServiceError from './service.error';

export default class ServiceBase {
    constructor(authenticationType = 'user') {
        if (authenticationType === 'user') {
            this.defaultConfig = { headers: { isAuth: true } };
        } else if (authenticationType === 'client2') {
            this.defaultConfig = { headers: { isClientCredentials: true } };
        } else if (authenticationType === 'upload') {
            this.defaultConfig = { headers: { 'Content-Type': 'multipart/form-data' } };
        }
    }

    get(url, config = {}) {
        return this.request('get', url, undefined, config);
    }

    post(url, payload, config = {}) {
        return this.request('post', url, payload, config);
    }

    put(url, payload, config = {}) {
        return this.request('put', url, payload, config);
    }

    patch(url, payload, config = {}) {
        return this.request('put', url, payload, config);
    }

    delete(url, config = {}) {
        return this.request('delete', url, config);
    }

    async request(method, url, payload, config) {
        try {
            let response;
            const requestConfig = ObjectUtil.mergeDeep({}, config, this.defaultConfig);
            if (['post', 'put', 'patch'].includes(method)) {
                response = await customAxios[method](url, payload, requestConfig);
            } else {
                response = await customAxios[method](url, requestConfig);
            }
            if (!response?.data?.data) {
                return response.data;
            }
            const {
                data: { data }
            } = response;


            return data;
        } catch (error) {
            process.env.NODE_ENV !== 'production' && console.error(3030, error);
            const data = error.response?.data || error;
            if (data) {
                process.env.NODE_ENV !== 'production' && console.log(data, 'hm2');
                if (data?.message) {
                    throw new ServiceError(data.message, data.type || 500);
                }
            }
            throw new ServiceError('Não foi possível realizar a operação, por favor tente novamente', 'UNKNOW');
        }
    }
}
