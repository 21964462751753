import { Modal, useTheme, CardHeader, Grid, Tabs, Tab, Divider, Card, Stack, Typography, Box, Dialog } from '@mui/material';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PropTypes from 'prop-types';
import getCategoryIconByURL from 'utils/categoriesIcons';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import FilesService from 'services/files.service';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

const FilesSv = new FilesService();
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const ModalItem = ({ item, open, handleClose }) => {
    const [tab, setTab] = useState(0);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const Icon = getCategoryIconByURL(item.categoryURL);
    const data = {
        ...item,
        name: (
            <Stack direction="row" alignItems="center" justifyContent="left">
                <Icon color={theme.palette.secondary.dark} />
                <Typography variant="h5" sx={{ ml: 1 }}>
                    {item.name}
                </Typography>
            </Stack>
        )
    };

    const FileItem = ({ file }) => {
        process.env.NODE_ENV !== 'production' && console.log(file, 30);
        return (
            <Box display="flex" flexDirection="column">
                <Box
                    position="relative"
                    display="inline-flex"
                    sx={{ width: '80px', alignSelf: 'center', texDecoration: 'none', color: 'inherit' }}
                    justifyContent="center"
                    component={'a'}
                    href={`${process.env.REACT_APP_BASE_URL}/download/${file.uuid}`}
                >
                    {file.type === 'pdf' ? (
                        <PictureAsPdfIcon style={{ fontSize: 50, color: 'red', border: '1px solid lightgray' }} />
                    ) : (
                        <ImageIcon style={{ fontSize: 50, border: '1px solid lightgray' }} />
                    )}
                </Box>
                <Typography variant="caption">
                    {file.fileName.length > 12 ? `${file.fileName.substring(0, 12)}...` : file.fileName}
                </Typography>
            </Box>
        );
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <MainCard
                sx={{
                    ...theme.components.Modal,
                    minWidth: { xs: '65vw', sm: '55vw', md: '50vw', lg: '45vw' },
                    maxHeight: { xs: '80vh' },
                    overFlowY: 'scroll'
                }}
                contentSX={{ p: '0 !important' }}
                headerSX={{ p: '8px 8px 8px 16px !important' }}
                title={data.name}
                darkTitle
                handleClose={handleClose}
            >
                <Grid container>
                    <Grid item xs={3} sx={{ p: 2 }}>
                        <Tabs
                            value={tab}
                            onChange={handleChange}
                            aria-label="checkout"
                            variant="scrollable"
                            scrollButtons="auto"
                            orientation="vertical"
                            allowScrollButtonsMobile
                            sx={theme.components.Tabs}
                        >
                            {data.info.map((step, i) => (
                                <Tab color="secondary" iconPosition="start" label={step.stepName} {...a11yProps(i)} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center" sx={{ p: 2 }}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={8}>
                        <Card
                            sx={{
                                mt: 0,
                                p: 2,
                                pl: '15px',
                                border: '0px solid',
                                minHeight: '250px',
                                maxHeight: '400px',
                                overflowY: 'scroll !important'
                            }}
                        >
                            <PerfectScrollbar>
                                {data.info.map(
                                    (step, index) =>
                                        step.type !== 'additionalServices' && (
                                            <TabPanel value={tab} index={index}>
                                                {step.fields.map((field) => (
                                                    <Stack direction="row" sx={{ my: 2 }}>
                                                        <Typography variant="body2" sx={{ fontWeight: 500, mr: 1 }}>
                                                            {field.name}
                                                        </Typography>
                                                        {field.inputType === 'upload' && field.value ? (
                                                            <Box display={'flex'} flexDirection="row">
                                                                {JSON.parse(field.value).map((file) => (
                                                                    <FileItem file={file} />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Typography variant="body2">{field.value}</Typography>
                                                        )}
                                                    </Stack>
                                                ))}
                                            </TabPanel>
                                        )
                                )}
                                <TabPanel value={tab} index={data.info.length - 1}>
                                    {data.addSvs.map((sv) => (
                                        <Stack direction="row" sx={{ my: 2 }}>
                                            <Typography variant="body2" sx={{ fontWeight: 500, mr: 1 }}>
                                                {sv.name}
                                            </Typography>
                                            <Typography variant="body2">{sv.amount}</Typography>
                                        </Stack>
                                    ))}
                                </TabPanel>
                            </PerfectScrollbar>
                        </Card>
                    </Grid>
                </Grid>
            </MainCard>
        </Modal>
    );
};

export default ModalItem;
