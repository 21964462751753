import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, IconButton, Stack, Typography, Link } from '@mui/material';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import LanguageSection from './LanguageSection';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconMenu2 } from '@tabler/icons';
import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router';
import ModalContatoSection from './ModalContatoSection';

export const ProfileUser = () => {
    const user = [
        {
            name: 'Kauan Cleuton',
            url: 'https://github.com/KauanCleuton.png'
        }
    ];

    return (
        <>
            {user.map((userData) => (
                <React.Fragment key={userData.name}>
                    <Avatar alt={userData.name} src={userData.url} />
                    <Typography variant='h5' component="span" color="#fff" display={{ xs: 'none', sm: 'block' }}>
                        {userData.name}
                    </Typography>
                </React.Fragment>
            ))}
        </>
    );
};

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const links = [
        { name: 'Home', key: 'home', action: 'anchor' },
        { name: 'Serviços', key: 'servicos', action: 'anchor' },
        { name: 'Sobre Nós', key: 'sobre-nos', action: 'anchor' },
        { name: 'Passo a Passo', key: 'passo-a-passo', action: 'anchor' },
        { name: 'Contato', key: '', action: 'whatsapp' },
        { name: 'Assessoria', key: '', action: 'whatsapp' }
    ];
    const path = useParams();
    console.log(path);
    const [activeLinkIndex, setActiveLinkIndex] = useState(0);
    const [stateModal, setStateModal] = useState(false);

    const handleClick = index => {
        setActiveLinkIndex(index);
    };

    const handleOpenModal = () => {
        setStateModal(!stateModal);
    };

    const handleCloseModal = () => {
        setStateModal(!stateModal);
    };

    const handleLinkClick = (item, index) => {
        if (item.action === 'anchor') {
            if (location.pathname === '/') {
                document.getElementById(item.key).scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate(`/#${item.key}`);
            }
        } else if (item.action === 'whatsapp') {
            window.open('https://api.whatsapp.com/send?phone=558592858796&text=Olá! Quero mais informações sobre os serviços da BrDocs', '_blank');
        } else {
            handleClick(index);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: 200,
                    display: 'flex'
                    //[theme.breakpoints.down('md')]: {
                    //width: 'auto'
                    //}
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* <SearchSection /> */}

            <Box
                sx={{
                    color: "red",
                    width: '700px',
                    display: { lg: 'flex', md: 'none', sm: 'none', xs: 'none' },
                    gap: '50px'
                }}
            >
                {links.map((item, index) => (
                    <Link
                        key={index}
                        style={{
                            textDecoration: 'none',
                            color: activeLinkIndex === index ? theme.palette.text.colorTextLink : '#fff',
                            marginRight: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleLinkClick(item, index)}
                    >
                        {item.name}
                    </Link>
                ))}
            </Box>

            <Stack direction={"row"} spacing={{ xs: 1, sm: 3 }}>
                <ProfileSection />
                <IconButton
                    sx={{
                        transition: 'all .2s ease-in-out',
                        background: 'transparent',
                        color: '#ffffff',
                        '&:hover': {
                            background: 'transparent',
                            color: theme.palette.secondary['200']
                        },
                    }}
                    onClick={handleLeftDrawerToggle}
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </IconButton>
            </Stack>
            {stateModal && <ModalContatoSection open={stateModal} handleClose={handleCloseModal} />}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
