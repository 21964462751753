import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Stack, Button, Typography } from '@mui/material/';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SNACK_BAR, RESET_SNACK_BAR } from 'store/actions';
import { Link } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = () => {
    const dispatch = useDispatch();
    const snackBar = useSelector((state) => state.customization.snackBar);

    const resetState = () => {
        dispatch({ type: RESET_SNACK_BAR });
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetState();
    };

    return (
        snackBar?.horizontal && (
            <Snackbar
                anchorOrigin={{ horizontal: snackBar.horizontal, vertical: snackBar.vertical }}
                open={snackBar.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={snackBar.type} sx={{ width: '100%' }}>
                    {snackBar.message}{' '}
                    {snackBar.undo && (
                        <Button
                            size="small"
                            color="primary"
                            onClick={(e) => {
                                dispatch(snackBar.undo);
                                resetState();
                            }}
                        >
                            DESFAZER
                        </Button>
                    )}
                </Alert>
            </Snackbar>
        )
    );
};
export default CustomizedSnackbars;
