import { useState, useRef, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';

import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';

import { IconLogout, IconSearch, IconSettings, IconUser, IconClipboardList } from '@tabler/icons';
import { extractDataFromSession } from 'utils/auth';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { LoginTwoTone, PersonAddAlt1, Support } from '@mui/icons-material';
import { SET_LOGIN_MENU, SET_LOGOUT } from 'store/actions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ProfileUser } from '..';

const MockData = {
    user: 'BRDocs User',
    role: 'Cliente'
};
const ProfileSection = () => {
    //const ProfileData = extractDataFromSession();
    const dispatch = useDispatch();
    const handleOpenModalLogin = (mode) => {
        setOpen(false);
        dispatch({ type: SET_LOGIN_MENU, opened: true, mode: mode || 'login' });
        //setOpen(false);
    };
    //process.env.NODE_ENV !== 'production' && console.log(ProfileData, 101010101029929239);
    const theme = useTheme();
    const profilePic = useSelector((state) => state.login?.data?.picture);
    const customization = useSelector((state) => state.customization);
    const login = useSelector((state) => state.login);
    //const data = login.data;
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    //const [data, setData] = useState(ProfileData);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false)
    const anchorRef = useRef(null);
    const handleLogout = () => {
        handleToggle();
        process.env.NODE_ENV !== 'production' && console.log('Logout');
        dispatch({ type: SET_LOGOUT });
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        window.location = '/';
        //setOpen(false);
    };
    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenMenu(false);
    }
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToggleMenu = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    }

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    mr: -2,
                    display: !login.auth && 'none',
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '12px',
                    transition: 'all .2s ease-in-out',
                    borderColor: 'transparent', //theme.palette.secondary.light,
                    //background: profilePic && `url(${profilePic})`,
                    backgroundColor: 'transparent', //theme.palette.primary.main, //light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.secondary.light,
                        //background: `${theme.palette.primary.main} !important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            // stroke: theme.palette.primary.light
                        }
                    },

                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            background: profilePic ? `url(${profilePic})` : theme.palette.primary.dark,
                            backgroundSize: profilePic && 'cover',
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            color: profilePic ? 'transparent' : 'black',
                            border: 'none',
                            '& svg': {
                                stroke: 'none'
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color={theme.palette.grey[900]}
                    />
                }
                // <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.secondary.light} />
                label={<Typography variant="h4" color={"#fff"}>{login.data?.nome}</Typography>}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="secondary"
            />
            <Chip
                sx={{
                    mr: -2,
                    display: !login.auth ? 'block' : 'none',
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '12px',
                    transition: 'all .2s ease-in-out',
                    borderColor: 'transparent', //theme.palette.secondary.light,
                    //background: profilePic && `url(${profilePic})`,
                    backgroundColor: 'transparent', //theme.palette.primary.main, //light,
                    // '&[aria-controls="menu-list-grow"], &:hover': {
                    //     borderColor: theme.palette.secondary.light,
                    //     //background: `${theme.palette.primary.main} !important`,
                    //     color: theme.palette.primary.light,
                    //     '& svg': {
                    //         // stroke: theme.palette.primary.light
                    //     }
                    // },

                    // '& .MuiChip-label': {
                    //     lineHeight: 0
                    // }
                }}
                icon={
                    <IconUser
                        style={{
                            position: 'relative',
                            top: '12px'
                        }}
                        stroke={1.5} size="1.3rem"
                        ref={anchorRef}
                        aria-controls={openMenu ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color={"#fff"}
                    />
                }
                // <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.secondary.light} />
                variant="outlined"
                ref={anchorRef}
                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggleMenu}
                color="secondary"
            />
            <Popper
                sx={{ display: !login.auth ? 'block' : 'none' }}
                placement="bottom-end"
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={openMenu} {...TransitionProps}>
                        < Paper>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                {/* <Typography variant="h4">Olá,</Typography> */}
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {/* {login.data?.nome} */}
                                                    Menu
                                                </Typography>
                                            </Stack>
                                            {/* <Typography variant="subtitle2">{login.data?.role === 0 ? 'Cliente' : 'Admin'}</Typography> */}
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 0 }}>
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/*<ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/usuario/configuracoes')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Configurações</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconClipboardList stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Meus Pedidos</Typography>} />
                                                </ListItemButton>*/}
                                                <ListItemButton
                                                    onClick={() => handleOpenModalLogin('login')}
                                                    selected={selectedIndex === 4}
                                                >
                                                    <ListItemIcon>
                                                        <LoginTwoTone size="small" fontSize="small" />
                                                    </ListItemIcon>
                                                    {/* <ListItemText primary={<Typography variant="body2">Minha Conta</Typography>} /> */}
                                                    <ListItemText primary={<Typography variant="body2">Login</Typography>} />

                                                </ListItemButton>
                                                <ListItemButton
                                                    onClick={() => handleOpenModalLogin('register')}
                                                    selected={selectedIndex === 4}
                                                >
                                                    <ListItemIcon>
                                                        <PersonAddAlt1 size="small" fontSize="small" />
                                                    </ListItemIcon>
                                                    {/* <ListItemText primary={<Typography variant="body2">Minha Conta</Typography>} /> */}
                                                    <ListItemText primary={<Typography variant="body2">Cadastro</Typography>} />

                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    href='/suporte'
                                                >
                                                    <ListItemIcon>
                                                        <Support stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Suporte</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <Popper
                sx={{ display: !login.auth ? 'none' : 'block', width: '100%' }}
                placement="bottom-start"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '30px', mt: '70px'}}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">Olá,</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400, display: { xs: "none", sm: "block" } }}>
                                                        {login.data?.nome}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">{login.data?.role === 0 ? 'Cliente' : 'Admin'}</Typography>
                                            </Stack>
                                        </Box>
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 0 }}>
                                                <Divider />
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        href={`/perfil`}
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                    >
                                                        <ListItemIcon>
                                                            <AccountCircleIcon stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Minha Conta</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        </Box>
                    </Transitions>
                )}
            </Popper>

            {/* <Stack spacing={2} direction={'row'}>
                <AnimateButton sx={{ display: login.auth && 'none', mr: 2 }}>
                    <Fab
                        component="div"
                        onClick={() => handleOpenModalLogin('register')}
                        size="small"
                        variant="circular"
                        color="primary"
                        sx={{
                            display: { xs: login.auth && 'none', sm: 'none' },
                            borderRadius: '4px',
                            width: '30px',
                            height: '30px',
                            minHeight: '30px'
                        }}
                    >
                        <IconButton color="inherit" size="small" disableRipple>
                            <PersonAddAlt1 size="small" fontSize="small" />
                        </IconButton>
                    </Fab>
                    <Button
                        disabled={false}
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModalLogin('register')}
                        sx={{
                            display: {
                                xs: 'none',
                                sm: login.auth ? 'none' : 'inline-flex'
                            },
                            mr: 1
                        }}
                        endIcon={<PersonAddAlt1 />}
                    >
                        Cadastrar
                    </Button>
                </AnimateButton>
                <AnimateButton sx={{ display: login.auth && 'none' }}>
                    <Fab
                        component="div"
                        onClick={() => handleOpenModalLogin('login')}
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{
                            display: { xs: login.auth && 'none', sm: 'none' },
                            backgroundColor: 'transparent',

                            border: '1px solid rgba(255, 188, 46, 0.5)',
                            color: theme.palette.primary.dark,
                            borderRadius: '4px',
                            width: '30px',
                            height: '30px',
                            minHeight: '30px',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <IconButton color="inherit" variant="outlined" size="small" disableRipple>
                            <LoginTwoTone size="small" fontSize="small" />
                        </IconButton>
                    </Fab>
                    <Button
                        disabled={false}
                        fullWidth
                        size="small"
                        type="submit"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenModalLogin('login')}
                        sx={{ display: { xs: 'none', sm: login.auth ? 'none' : 'inline-flex' }, mr: 1 }}
                        endIcon={<LoginTwoTone />}
                    >
                        Login
                    </Button>
                </AnimateButton>
            </Stack> */}
        </>
    );
};

export default ProfileSection;
