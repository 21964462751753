import { extractDataFromSession, isLoggedIn } from 'utils/auth';
import * as actionTypes from './actions';

export const initialState = {
    opened: false,
    func: null,
    mode: 'login',
    data: extractDataFromSession(),
    auth: isLoggedIn()
};

const loginReducer = (state = initialState, action) => {
    const opened = action.opened !== undefined ? action.opened : state.opened;
    const mode = action.mode || state.mode;
    switch (action.type) {
        case actionTypes.SET_LOGIN_MENU:
            process.env.NODE_ENV !== 'production' &&
                console.log(303012, { ...state, opened: action.opened, func: action.func, mode: action.mode });
            const newSt = { ...state, opened, func: action.func, mode };
            process.env.NODE_ENV !== 'production' && console.log('ns', newSt);
            return newSt;
        case actionTypes.START_LOGIN_FUNC:
            process.env.NODE_ENV !== 'production' && console.log(state.func, 83010);
            state.func && state.func();
            return { ...state, func: null };
        case actionTypes.SET_LOGOUT:
            return {
                opened: false,
                func: null,
                data: null,
                auth: false
            };
        case actionTypes.SET_LOGIN_DATA:
            const data = extractDataFromSession();
            const newState = { ...state, data, auth: true };
            process.env.NODE_ENV !== 'production' && console.log(3039, newState);
            return newState;
        case actionTypes.UPDATE_USER_DATA:
            return { ...state, mode: 'update', opened, func: action.func };
        default:
            return state;
    }
};

export default loginReducer;
