export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            400: theme.colors?.success400,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
            colorTextLink: '#FFAC2E',
            colorTitleBlue: '#0D1A51'
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault,
            header: "#0F458D",
            footer: "linear-gradient(266.39deg, #061039 2.07%, #081B4B 21.62%, #0A285F 43.56%, #0D3878 69.77%, #0F4389 89.01%);",
            blog: "linear-gradient(77deg, rgba(7,24,70,1) 0%, rgba(7,24,70,1) 12%, rgba(9,31,81,1) 23%, rgba(10,42,98,1) 33%, rgba(10,42,98,1) 43%, rgba(12,49,109,1) 53%, rgba(11,47,106,1) 61%, rgba(12,54,117,1) 66%, rgba(14,62,129,1) 80%, rgba(15,68,139,1) 99%);"
            
        }
    };
}
