import { useState, useEffect } from 'react';

import { Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import ServicesSv from 'services/services.service';
import getCategoryIconByURL from 'utils/categoriesIcons';
import { useDispatch } from 'react-redux';
import { SET_NAVIGATION } from 'store/actions';

const Services = new ServicesSv();

const MenuList = () => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();

    const [menuItems, setMenuItems] = useState({ items: [menuItem.items[0]] });
    process.env.NODE_ENV !== 'production' && console.log(300000, menuItems);
    const getServicesMenuItems = async () => {
        try {
            const res = await Services.getCategories();
            const servicesData = await Services.getServicesData();
            process.env.NODE_ENV !== 'production' && console.log(res, servicesData, 900001);
            const categoriesWithServices = res.map((category) => {
                const services = servicesData.filter((service) => service.categoria === category.nome);
                const newServices = services.map((service) => {
                    return { id: service.id, title: service.nome, type: 'item', url: `/servicos/${service.url}`, breadcrumbs: true };
                });
                return {
                    title: category.nome,
                    id: category.id,
                    type: 'collapse',
                    children: newServices,
                    breadcrumbs: true,
                    url: `/categorias/${category.url}`,
                    icon: getCategoryIconByURL([category.url], 1) || ''
                };
            });
            const servicesMenuList = {
                id: 'services',
                title: 'Serviços',
                type: 'group',
                children: categoriesWithServices,
                breadcrumbs: true
            };
            const newNavigation = { items: [menuItems.items[0], servicesMenuList] };
            dispatch({ type: SET_NAVIGATION, navigation: newNavigation });
            setMenuItems(newNavigation);
        } catch (err) {
            process.env.NODE_ENV !== 'production' && console.log(err);
        }
    };

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && console.log(33, menuItems);
        getServicesMenuItems();
    }, []);
    useEffect(() => {
        process.env.NODE_ENV !== 'production' && console.log(open, 6060);
    }, [open]);
    const navItems = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} open={open} setOpen={setOpen} selected={selected} setSelected={setSelected} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Erro
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
