import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';

import AuthWrapper from '../AuthWrapper';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { useState } from 'react';
import AuthRegister from '../auth-forms/AuthRegister';
import AuthUpdate from '../auth-forms/AuthUpdate';
import AuthForgotPassword from '../auth-forms/AuthForgotPassword';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LOGIN_MENU } from 'store/actions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const ModalLogin = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mode = useSelector((state) => state.login.mode);
    //const [mode, setMode] = useState('login');
    const setMode = (mode) => dispatch({ type: SET_LOGIN_MENU, mode: mode });
    const closeModal = () => dispatch({ type: SET_LOGIN_MENU, opened: false });
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    process.env.NODE_ENV !== 'production' && console.log('process.env.TEST:', process.env.REACT_APP_TEST);
    process.env.NODE_ENV !== 'production' && console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            {/*<PerfectScrollbar component="div" sx={{ p: 2, my: 2 }}>*/}
            <Grid item sx={{ m: { xs: 0, sm: 2 }, mb: 0 }}>
                <AuthCardWrapper>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item sx={{ mb: 3 }}>
                            <IconButton
                                onClick={closeModal}
                                sx={{
                                    '&:hover': {
                                        color: theme.palette.primary.dark,
                                        backgroundColor: 'transparent'
                                    },
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}
                            >
                                <CloseRoundedIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                            <Stack alignItems="center" justifyContent="center" spacing={0}>
                                <Link to="#">
                                    <Logo variant={'blueYellow'} />
                                </Link>
                                <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    Mais que um despachante online.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            {mode === 'login' && <AuthLogin modal setMode={setMode} />}
                            {mode === 'register' && <AuthRegister modal setMode={setMode} />}
                            {mode === 'forgotPassword' && <AuthForgotPassword modal setMode={setMode} />}
                            {mode === 'update' && <AuthUpdate modal setMode={setMode} />}
                        </Grid>
                        {mode !== 'update' && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item container direction="column" alignItems="center" xs={12}>
                                        {(mode === 'login' || mode === 'forgotPassword') && (
                                            <Typography
                                                onClick={() => setMode('register')}
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                            >
                                                Não possui uma conta?
                                            </Typography>
                                        )}
                                        {mode === 'register' && (
                                            <Typography
                                                onClick={() => setMode('login')}
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                            >
                                                Já possui conta?
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </AuthCardWrapper>
            </Grid>
            {/*</PerfectScrollbar>*/}
        </Grid>
    );
};

export default ModalLogin;
