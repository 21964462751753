import { Favorite, FavoriteBorder, DeleteTwoTone, DeleteOutline, EditTwoTone } from '@mui/icons-material';
import { Button, Checkbox, Card, CardContent, Divider, Stack, Typography, Grid, Chip, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_ITEM, SET_SNACK_BAR, SET_CART, SET_MODAL_ITEM } from 'store/actions';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import getCategoryIconByURL from 'utils/categoriesIcons';
import ModalItem from './ModalItem';
import { useState, useEffect } from 'react';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import { useLocation } from 'react-router';

const CartItems = ({ xs, md }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const open = useSelector((state) => state.cart.openItem);
    const theme = useTheme();

    const handleClose = () => dispatch({ type: SET_MODAL_ITEM, openItem: -1 });
    const setOpen = (index) => {
        const state = { type: SET_MODAL_ITEM, openItem: index };
        dispatch(state);
    };

    const handleAddFavorite = (item) => {};
    const removeFromCart = (index) => {
        const undo = { type: SET_CART, state: JSON.parse(JSON.stringify(cart)) };
        dispatch({ type: SET_SNACK_BAR, state: { open: true, type: 'warning', undo, message: 'Serviço removido do carrinho!' } });
        dispatch({ type: REMOVE_ITEM, index });
    };

    const Icon = (props) => {
        const { item, ...rest } = props;
        const CartIcon = item.categoryURL && getCategoryIconByURL(item.categoryURL, 1);
        return <CartIcon {...rest} />;
    };

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && console.log(open, 900);
        process.env.NODE_ENV !== 'production' && console.log(cart, 901);
    }, [open, cart]);

    process.env.NODE_ENV !== 'production' && console.log('cartItems', cart.items);

    return cart.items.map((data, index) => (
        <>
            <ModalItem open={open === index} handleClose={handleClose} item={data} />
            <Grid item xs={xs} md={md}>
                <MainCard
                    sx={{ border: '2px solid', borderColor: '#E3E8EF', p: 0, '& .MuiCardContent-root:last-child': { pb: 0 } }}
                    contentSX={{ p: 0, pb: 0 }}
                >
                    <Grid container direction="column" sx={{ p: 0.5 }}>
                        <Grid item xs={xs}>
                            <Stack direction="row" alignItems={'center'}>
                                {data.categoryURL && <Icon item={data} size={40} strokeWidth={1.5} color={theme.palette.secondary.dark} />}
                                <Stack direction="column" sx={{ width: '100%' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 500, mt: 0.5, ml: 0.5 }}>
                                        {data.name}
                                    </Typography>
                                    {data.addSvs.map((sv) => (
                                        <Stack direction="row" alignItems="center" sx={{ pl: 1 }}>
                                            <Typography variant="h6" color="gray" sx={{ fontWeight: 300, mt: 0.5, ml: 0.5 }}>
                                                {sv.name}
                                                {(sv.amount !== 1 || sv.trad) && (
                                                    <span
                                                        style={{
                                                            marginLeft: '5px',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            color: 'white',
                                                            background: 'gray',
                                                            padding: '2px 4px'
                                                        }}
                                                    >
                                                        {sv.trad || sv.amount}
                                                    </span>
                                                )}
                                            </Typography>
                                        </Stack>
                                    ))}
                                    <Chip
                                        color={'primary'}
                                        sx={theme.components.CartItemValueChip}
                                        label={
                                            'R$ ' +
                                            parseFloat(data.serviceTotalValue).toLocaleString('pt-BR', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })
                                        }
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mt: 1 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{ p: 1 }} justifyContent={'space-between'}>
                                <Stack
                                    direction="row"
                                    alignItems={'center'}
                                    sx={theme.components.CartItemHandler}
                                    onClick={() => {
                                        setOpen(index);
                                    }}
                                >
                                    <IconButton
                                        sx={{
                                            '&:hover': { color: theme.palette.primary.dark, backgroundColor: 'transparent' }
                                        }}
                                    >
                                        <FindInPageTwoToneIcon sx={{ fontSize: '1.6rem !important' }} />
                                    </IconButton>
                                    <Typography
                                        variant="body2"
                                        ml={-0.5}
                                        sx={{
                                            fontWeight: 450
                                        }}
                                        color="gray"
                                    >
                                        Visualizar
                                    </Typography>
                                </Stack>
                                {pathname !== '/checkout' && (
                                    <Stack
                                        direction="row"
                                        alignItems={'center'}
                                        sx={theme.components.CartItemHandler}
                                        onClick={() => removeFromCart(index)}
                                    >
                                        <IconButton
                                            sx={{
                                                '&:hover': { color: theme.palette.primary.dark, backgroundColor: 'transparent' }
                                            }}
                                        >
                                            <DeleteOutline sx={{ fontSize: '1.6rem !important' }} />
                                        </IconButton>
                                        <Typography
                                            variant="body2"
                                            ml={-0.5}
                                            sx={{
                                                fontWeight: 450
                                            }}
                                            color="gray"
                                        >
                                            Apagar
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
        </>
    ));
};

export default CartItems;
