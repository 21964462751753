import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import NavItem from '../NavItem';
import { MENU_OPEN, SET_MENU } from 'store/actions';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

const NavCollapse = ({ menu, level, open, setOpen, selected, setSelected }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    const handleClick = () => {
        setOpen(open === menu.id ? false : menu.id);
        setSelected(selected !== menu.id ? menu.id : null);
        dispatch({ type: MENU_OPEN, id: menu.id });
    };
    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            process.env.NODE_ENV !== 'production' && console.log(382221, item, pathname);
            if (item.url === pathname) {
                process.env.NODE_ENV !== 'production' && console.log(382222);
                setOpen(id);
                setSelected(id);
            }
        });
    };
    const checkCollapse = () => {
        if (menu.children) {
            process.env.NODE_ENV !== 'production' && console.log(382220, menu.children, pathname);
            menu.children.forEach((item) => {
                process.env.NODE_ENV !== 'production' && console.log(382224);
                if (item.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (item.url === pathname) {
                    process.env.NODE_ENV !== 'production' && console.log(382225, menu.id, ' iurl = ', item.url, ' pname= ', pathname);
                    setSelected(menu.id);
                    setOpen(menu.id);
                }
            });
        }
    };
    const { pathname } = useLocation();

    useEffect(() => {
        checkCollapse();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return (
                    <NavCollapse
                        key={item.id}
                        menu={item}
                        level={level + 1}
                        open={open}
                        setOpen={setOpen}
                        selected={selected}
                        setSelected={setSelected}
                    />
                );
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} setSelected={setSelected} setOpen={setOpen} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body4'} color="inherit" sx={{ my: 'auto' }}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open === menu.id ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open === menu.id} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.light,
                            display: 'none'
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
