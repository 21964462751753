import './style.scss';
// BR Docs Loader
const Loader = ({ variant = 1 }) => {
    const fillBg = { 1: '#fff', 2: '#eef2f6' };
    const emptyColor = { 1: fillBg[2], 2: '#d2d4d8' };
    return (
        <div class="x-reset-container">
            <div class="c-leaf" style={{ background: emptyColor[variant] }}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="c-leaf__icon" fill={fillBg[variant]} viewBox="0 0 620 370">
                    <g transform="translate(-20.000000,370) scale(0.102000,-0.102000)" stroke="none">
                        <path
                            d="M0 1860 l0 -1860 3230 0 3230 0 0 1860 0 1860 -3230 0 -3230 0 0
-1860z m1355 1678 c4 -40 5 -157 3 -260 -3 -171 -5 -189 -24 -215 -12 -15 -34
-31 -50 -35 -16 -4 -63 -14 -104 -23 -41 -10 -108 -31 -149 -47 -141 -59 -253
-195 -331 -401 -36 -95 -80 -295 -80 -363 0 -13 -8 -27 -17 -32 -10 -5 -138
-12 -285 -15 l-268 -5 0 72 c0 113 27 312 59 437 37 145 115 337 177 435 94
152 255 288 459 389 198 97 328 132 507 134 l96 1 7 -72z m1840 -975 c114 -41
194 -91 276 -172 193 -192 241 -470 131 -753 l-29 -77 34 -33 c76 -72 166
-197 213 -293 71 -146 84 -203 85 -370 0 -119 -3 -151 -23 -215 -62 -199 -190
-355 -372 -458 -31 -17 -104 -47 -163 -67 l-108 -35 -909 0 -909 0 -6 33 c-10
50 -1 1544 8 1554 5 4 369 11 810 15 441 4 813 10 827 13 51 13 101 55 126
108 61 131 3 277 -129 326 -19 8 -294 11 -838 11 l-809 0 2 218 3 217 40 7
c22 3 407 4 855 1 l815 -5 70 -25z m2370 28 c337 -7 341 -8 409 -34 158 -62
306 -196 375 -339 95 -198 94 -398 -3 -593 -81 -163 -204 -271 -395 -346 -85
-34 -96 -41 -94 -61 2 -13 122 -261 267 -553 145 -292 267 -538 270 -547 8
-26 -30 -29 -269 -26 l-210 3 -220 435 c-251 497 -277 542 -346 604 -63 56
-127 81 -254 96 -111 14 -470 19 -497 8 -17 -7 -18 -41 -18 -525 0 -285 -3
-542 -6 -571 l-7 -52 -77 0 c-42 0 -138 3 -213 7 l-137 6 0 1231 c0 677 4
1236 8 1242 4 7 16 14 27 17 30 7 1005 6 1390 -2z"
                        />
                        <path
                            d="M1963 1243 c-59 -3 -85 -8 -95 -20 -10 -12 -14 -82 -16 -307 -2 -160
-1 -310 3 -334 l6 -43 677 4 c559 2 684 5 717 17 57 21 142 110 178 189 63
134 33 285 -79 391 -83 79 -122 92 -304 101 -149 7 -918 8 -1087 2z"
                        />
                        <path
                            d="M4601 2133 c-25 -21 -28 -59 -23 -311 l2 -133 613 3 613 3 49 26
c156 80 151 317 -8 402 -42 22 -45 22 -632 25 -562 3 -592 3 -614 -15z"
                        />
                    </g>
                </svg>
                <div class="c-leaf_fills">
                    <div class="c-leaf__fill"></div>
                    <div class="c-leaf__fill2"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
