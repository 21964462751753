import config from 'config';

import * as actionTypes from './actions';

export const initialState = {
    isOpen: [],
    defaultId: 'default',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    snackBar: { open: false, type: 'success', message: '', horizontal: 'left', vertical: 'bottom', timeout: 6000 },
    categories: [],
    navigation: []
};

const initSnackBar = { open: false, type: 'success', message: '', horizontal: 'left', vertical: 'bottom', timeout: 6000 };

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.RESET_SNACK_BAR:
            return {
                ...state,
                snackBar: { ...state.snackBar, open: false, message: '', horizontal: 'left', vertical: 'bottom', timeout: 6000 }
            };
        case actionTypes.SET_SNACK_BAR:
            const type = action.state.type || 'success';
            return {
                ...state,
                snackBar: { ...state.snackBar, ...action.state, type }
            };
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_CATEGORIES:
            //process.env.NODE_ENV !== 'production' && console.log(3910, action);
            return {
                ...state,
                categories: action.categories
            };
        case actionTypes.SET_NAVIGATION:
            return {
                ...state,
                navigation: action.navigation
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        default:
            return state;
    }
};

export default customizationReducer;
