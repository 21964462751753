import ServiceBase from './service.base';

export default class UploadService extends ServiceBase {
    constructor() {
        super('upload');
    }
    uploadFile(payload) {
        return this.post(`/upload`, payload);
    }
    downloadFile(
        uuid,
        config = {
            responseType: 'blob' // important
        }
    ) {
        return this.get(`/download/${uuid}`);
    }
}
