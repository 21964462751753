import unidecode from 'unidecode';
import { addDays, isWeekend } from 'date-fns';
import { format, parseISO } from 'date-fns';

// Função para calcular a Páscoa (domingo de Páscoa)
const calculateEaster = (year) => {
    const f = Math.floor,
        G = year % 19,
        C = f(year / 100),
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);
    return new Date(year, month - 1, day);
};

// Função para obter feriados móveis e fixos de um ano específico
const getBrazilianHolidays = (year) => {
    const easter = calculateEaster(year);
    return [
        `${year}-01-01`, // Confraternização Universal
        format(addDays(easter, -47), 'yyyy-MM-dd'), // Carnaval
        format(addDays(easter, -46), 'yyyy-MM-dd'), // Carnaval
        format(addDays(easter, -2), 'yyyy-MM-dd'), // Sexta-feira Santa
        `${year}-04-21`, // Tiradentes
        `${year}-05-01`, // Dia do Trabalho
        format(addDays(easter, 60), 'yyyy-MM-dd'), // Corpus Christi
        `${year}-09-07`, // Independência do Brasil
        `${year}-10-12`, // Nossa Senhora Aparecida
        `${year}-11-02`, // Finados
        `${year}-11-15`, // Proclamação da República
        `${year}-12-25` // Natal
    ];
};

const isHoliday = (date) => {
    const year = date.getFullYear();
    const holidays = getBrazilianHolidays(year);
    const formattedDate = format(date, 'yyyy-MM-dd');
    return holidays.includes(formattedDate);
};

export const addBusinessDays = (startDate, numberOfDays) => {
    let resultDate = startDate;
    let addedDays = 0;

    while (addedDays < numberOfDays) {
        resultDate = addDays(resultDate, 1);
        if (!isWeekend(resultDate) && !isHoliday(resultDate)) {
            addedDays++;
        }
    }

    return resultDate.toLocaleDateString('pt-BR');
};

export const clearFields = (field, setFields) => {
    const newObject = {};
    Object.keys(field).forEach((key) => {
        newObject[key] = '';
    });
    setFields(newObject);
};

export const removeSpecialChar = (filename) => {
    var asciiName = unidecode(filename);
    return asciiName.replace(/[^a-zA-Z0-9.]/g, '');
};

export const currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, ',');
};

export const parseNumber = (num) => {
    const newStr = num.replace('R$', '');
    const pFloat = parseFloat(newStr).toFixed(2);
    return pFloat;
};

export const addDaysToDate = (date, days) => {};

export const difDates = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
};

export const transformString = (str) => {
    let lowerCaseStr = str.toLowerCase();

    let removedSpecialCharsStr = lowerCaseStr.replace(/[^\w\s]/gi, '');

    let finalStr = removedSpecialCharsStr.replace(/\s+/g, '-');

    return finalStr;
};

export const parseBRCurrency = (value) =>
    'R$ ' + parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const parseCEP = (cepStr) => `${String(cepStr).slice(0, 5)}-${String(cepStr).slice(5)}`;

export const validateCardInfo = (cardInfo) => {
    const { cardNumber, cardYear, cardMonth, cardCvv, cardHolder } = cardInfo;
    const expiryDate = `${cardMonth}/${String(cardYear)}`;
    // validate card number - using Luhn algoritm
    const validateCardNumber = (number) => {
        const num = number.replace(/[^\d]/g, '');
        if (num.length < 14) return false;
        let total = 0;

        for (let i = num.length - 1; i >= 0; i--) {
            let value = parseInt(num[i]);

            if ((num.length - i) % 2 === 0) {
                value *= 2;

                if (value > 9) {
                    value -= 9;
                }
            }

            total += value;
        }

        return total % 10 === 0;
    };

    const validateExpiryDate = (expiry) => {
        const [month, year] = expiry.split('/');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        if (year < currentYear || (year == currentYear && month < currentMonth)) {
            return false;
        }

        return true;
    };
    let errors = {};
    const validateCVV = (cvv) => {
        const cvvRegex = /^[0-9]{3,4}$/;
        return cvvRegex.test(cvv);
    };

    const validateName = (name) => {
        return name.length > 0;
    };

    if (!validateCardNumber(cardNumber)) {
        errors = { ...errors, cardNumber: 'Número de cartão inválido' };
    }
    if (!validateExpiryDate(expiryDate)) {
        errors = { ...errors, expiryDate: 'Data de expiração inválida' };
    }

    if (!validateCVV(cardCvv)) {
        errors = { ...errors, cvv: 'CVV inválido' };
    }

    if (!validateName(cardHolder)) {
        errors = { ...errors, cardHolder: 'Nome no cartão é obrigatório' };
    }
    if (Object.keys(errors).length !== 0) throw new ValidationError({ ...errors, submit: 'Verifique as informações do cartão' });
    return true;
};

export const validateCPF = (cpf) => {
    if (!cpf || typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[^\d]+/g, '');

    if (
        cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    )
        return false;

    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;

    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;

    return true;
};

// CNPJ Validation
export const validateCNPJ = (cnpj) => {
    if (!cnpj || typeof cnpj !== 'string') return false;
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    )
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
};
export const validateCPFCNPJ = (value) => {
    const validation = validateCPF(value) || validateCNPJ(value);
    if (!validation) throw new ValidationError({ cpfCnpj: 'CPF/CNPJ inválido', submit: 'CPF/CNPJ inválido' });
    return true;
};

export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) throw new ValidationError({ email: 'Email inválido', submit: 'Email inválido' });
};
export const validatePhone = (phone) => {
    if (phone.replace(/[^\d]/g, '').length < 10) throw new ValidationError({ phone: 'Telefone inválido', submit: 'Telefone inválido' });
};

export const validateAddress = (address) => {
    let errors = {};
    if (!address.address) errors = { ...errors, address: 'Insira o endereço!' };
    if (address.postalCode.replace(/[^\d]/g, '').length !== 8) errors = { ...errors, postalCode: 'Código Postal inválido' };
    if (!address.addressNumber) errors = { ...errors, addressNumber: 'Insira o número!' };
    if (!address.city) errors = { ...errors, city: 'Insira a cidade!' };
    if (!address.state) errors = { ...errors, state: 'Insira o estado!' };
    if (Object.keys(errors).length !== 0) ValidationError({ ...errors, submit: 'Verifique os da dos de endereço' });
    return true;
};

export const validateFullName = (fullName) => {
    if (!fullName) throw new ValidationError({ fullName: 'Campo obrigatório!' });
    if (!fullName.includes(' ')) throw new ValidationError({ fullName: 'O nome deve ser completo', submit: 'O nome deve ser completo' });
};

export const validateCardHolderInfo = (form) => {
    validateFullName(form.fullName);
    validateCPFCNPJ(form.cpfCnpj);
    validateEmail(form.email);
    validatePhone(form.phone);
};

export class ValidationError extends Error {
    constructor(errors) {
        super();
        this.name = 'ValidationError';
        this.errors = errors;
    }
}

export const removeAccents = (str) => {
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn';
    return str
        .split('')
        .map((char) => {
            const index = accents.indexOf(char);
            return index !== -1 ? accentsOut[index] : char;
        })
        .join('');
};
