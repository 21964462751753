import ServiceBase from './service.base';

export default class ClientService extends ServiceBase {
    constructor() {
        super('user');
    }

    changePassword(payload) {
        return this.post(`/auth/change-password`, payload);
    }

    addNewUserAddress(payload) {
        return this.post(`/address`, payload);
    }

    removeUserAddress(id) {
        return this.delete(`/address/${id}`);
    }

    getUserAddress() {
        return this.get(`/address`);
    }

    createPayment(payload) {
        return this.post(`/orders`, payload);
    }

    getOrderCheckout(orderId) {
        return this.get(`/orders/${orderId}/checkout`);
    }

    getOrderStatus() {
        return this.get(`/orders/`);
    }

    getOrderDetails(orderId) {
        return this.get(`/orders/${orderId}`);
    }

    getUserData() {
        return this.get(`/user`);
    }
    
    editUserData(payload) {
        return this.put(`/user`, payload);
    }

    saveCreditCard(payload) {
        return this.post(`/asaas/credit-card`, payload);
    }

    getCreditCard() {
        return this.get(`/asaas/credit-card`);
    }

    removeCreditCard(id) {
        return this.delete(`/asaas/credit-card/${id}`);
    }

}
