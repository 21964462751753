import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Routes from 'routes';

import themes from 'themes';

import NavigationScroll from 'layout/NavigationScroll';

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <>
            <Helmet>
                {/* Google GTM */}
                <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','AW-11280111038');`}
                </script>
                {/* <!-- Meta Pixel Code -->*/}
                <script>
                    {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '582242614075269');
  fbq('track', 'PageView');`}
                </script>
                <noscript>
                    {`
                    <img
                        height="1"
                        width="1"
                        style="display:none"
                        src="https://www.facebook.com/tr?id=582242614075269&ev=PageView&noscript=1"
                    />`}
                </noscript>
                {/*<!-- End Meta Pixel Code -->*/}

                {/*<!-- Meta Pixel Code -->*/}
                <script>
                    {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '311731871411921');
  fbq('track', 'PageView');`}
                </script>
                <noscript>
                    {` <img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=311731871411921&ev=PageView&noscript=1"
/>`}
                </noscript>
                {/*<!-- End Meta Pixel Code -->*/}
            </Helmet>
            <StyledEngineProvider>
                <GoogleOAuthProvider clientId="469890071238-tcr30cpg2amndd7da7g2fgv748gdrtpc.apps.googleusercontent.com">
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </GoogleOAuthProvider>
            </StyledEngineProvider>
        </>
    );
};

export default App;
