import React from 'react';
import { Box, Typography, List, ListItem, Link, Grid } from '@mui/material';
import logo from 'assets/images/landing/brdocs-logo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Container } from '@mui/system';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CATEGORIES } from 'store/actions';
import ServicesSv from 'services/services.service';
import { useEffect } from 'react';
import CustomContainer from 'ui-component/CustomContainer';
import { NavLink } from 'react-router-dom';

const BRTechLogo = styled('img')({
    width: '143.44px',
    height: 'auto',
    position: 'relative',
    bottom: 5
});
const fontParamsBody = {
    fontSize: { lg: '.9rem', md: '0.9rem', sm: '.8rem', xs: '.766rem' }
}
const Footer = () => {
    const theme = useTheme();
    const ServSv = new ServicesSv();
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.customization.categories);
    const getData = async () => {
        const categoriesData = await ServSv.getCategories();
        process.env.NODE_ENV !== 'production' && console.log(categoriesData, 30302);
        dispatch({ type: SET_CATEGORIES, categories: categoriesData });
        //setCategories(categoriesData);
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <CustomContainer>
            <Box mt={0} py={2}>
                <Grid container maxWidth={"lg"} justifyContent="flex-end" direction="row" spacing={2}>
                    <Grid item xs>
                        <BRTechLogo src={logo} alt="Logo" />
                        <Typography sx={{ ...fontParamsBody, color: "white" }}>
                            CNPJ 48.352.955/0001-34 <br />
                            Av. Washington Soares, <br /> 85 Lj SS08
                            Edson Queiroz - Fortaleza / Ce <br />

                            Av. Presidente Juscelino <br /> Kubitschek, 1327 <br />
                            Vila Nova Conveição, <br /> São Paulo/ SP <br />
                            CEP: 04.543-011
                        </Typography>
                    </Grid>




                    <Grid item xs={6} sm={2} display={{xs: "none", sm: "block"}}>
                        <Grid container>
                            <Grid item xs={12} >
                                <Grid item xs={12} color="white">
                                    <Typography variant="h5" sx={{ color: 'white' }}>
                                        Nossos Serviços
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} color="white">
                                    <Link href="/categorias/#registro-civil" sx={{ color: 'white', textDecoration: 'none' }} color="inherit">
                                        Registro Civil
                                    </Link>
                                </Grid>
                                <Grid item xs={12} color="white">
                                    <Link href="/categorias/#registro-de-imoveis" color="inherit" sx={{ color: 'white', textDecoration: 'none' }} >
                                        Registro de Imóveis
                                    </Link>
                                </Grid>
                                <Grid item xs={12} color="white">
                                    <Link href="/categorias/#notas" sx={{ color: 'white', textDecoration: 'none' }} color="inherit">
                                        Notas
                                    </Link>
                                </Grid>
                                <Grid item xs={12} color="white">
                                    <Link href="/categorias/#federais" sx={{ color: 'white', textDecoration: 'none' }} color="inherit">
                                        Outros
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={2} display={{xs: "none", sm: "block"}}>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                            Transparência
                        </Typography>
                        <Grid container mt={1} direction={'column'}>
                            <Grid item>
                            <Link href={`/termos`} sx={{ color: 'white', textDecoration: 'none' }}>
                            Termos de Uso                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={`/politica-privacidade`} sx={{ color: 'white', textDecoration: 'none' }}>
                                    Política de Privacidade
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={2} display={{xs: "none", sm: "block"}}>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                            Sobre Nós
                        </Typography>
                        <Grid container mt={1} direction={'column'}>
                            <Grid item>
                                <Link href={`#sobre-nos`} sx={{ color: 'white', textDecoration: 'none' }}>
                                    Nossa Empresa
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={2} display={{xs: "none", sm: "block"}}>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                            Dúvidas
                        </Typography>
                        <Grid container mt={1} direction={'column'}>
                            <Grid item>
                                <Link href={`#servicos`} sx={{ color: 'white', textDecoration: 'none' }}>
                                    Serviços
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={'https://api.whatsapp.com/send?phone=558592858796&text=Olá! Quero mais informações sobre os serviços da BrDocs.'} target='blank' sx={{ color: 'white', textDecoration: 'none' }}>
                                    Solicitações
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={2} direction={'column'} spacing={5}>

                        <Grid item xs={6} sm={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Typography variant="h5" sx={{ color: 'white' }}>
                                        Nossas Redes
                                    </Typography>
                                    <Box
                                        sx={{
                                            color: theme.palette.secondary.contrastText,
                                            width: '100px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end',
                                            '& a': { color: theme.palette.secondary.contrastText },
                                            mt: 1
                                        }}
                                    >
                                        <Link href="https://www.facebook.com/brdocsdocumentosdigitais" target="_blank" rel="noopener noreferrer">
                                            <FacebookIcon />
                                        </Link>
                                        <Link href="https://instagram.com/brdocsdigitais?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                            <InstagramIcon />
                                        </Link>
                                        <Link href="https://www.linkedin.com/in/br-docs-631005275" target="_blank" rel="noopener noreferrer">
                                            <LinkedInIcon />
                                        </Link>
                                    </Box>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{ color: "white" }}>Blog</Typography>
                                    <Link href="http://brdocs.top" color="inherit" style={{ textDecoration: "none", color: "white" }} target="_blank">
                                        www.brdocsblog.com
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} xs={12} sm={12} md={12} alignItems={"start"}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ color: "white" }} variant="h6">Contato</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{ color: "white" }} variant="body1">+ 55 11 96647.7928</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{ color: "white" }} variant="body1">+ 55 85 99285.8796</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            fontSize: { xs: '.766rem', sm: '.9rem' },
                                            color: "white"
                                        }}>
                                            <Link href="mailto:contato@brdocsdigitais.com" color="inherit" style={{ textDecoration: "none" }}>
                                                contato@brdocsdigitais.com
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Box>
        </CustomContainer>
    );
};

export default Footer;
