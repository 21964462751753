import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/AuthLayout';

const AuthLogin = Loadable(lazy(() => import('views/auth/auth-forms/AuthLogin')));
const AuthRegister = Loadable(lazy(() => import('views/auth/auth-forms/AuthRegister')));

const AuthenticationRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/sign-up',
            element: <AuthRegister />
        }
    ]
};

export default AuthenticationRoutes;
