import { Box, Grid, IconButton, Modal, Typography, styled, TextField, FormControlLabel, Checkbox } from "@mui/material"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import imageContato from 'assets/images/image-contato.svg'

const StyledImg = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    borderRadius: 2, // Adjust as needed
});

const fontBodyParams = {
    fontSize: { lg: '1rem', md: '1.0045rem', sm: '1.1rem', xs: '.9rem' },
    lineHeight: { lg: '1.1rem', md: '1.022rem', sm: '1.2rem', xs: '1rem' },
    color: "#1C1C1C"
}
const fontParams = {
    fontSize: { lg: '2rem', md: '1.7rem', sm: '1.5rem', xs: '1.2rem' },
    lineHeight: { lg: '2.2rem', md: '1.9rem', sm: '1.6rem', xs: '1.4rem' },
    color: "#0D1A51",
    fontWeight: "bold"
}
const fontParamsSub = {
    fontSize: { lg: '1.375rem', md: '1.2rem', sm: '1.1rem', xs: '1rem' },
    lineHeight: { lg: '2.2rem', md: '1.9rem', sm: '1.6rem', xs: '1.4rem' },
    color: "#1C1C1C",
}

const ModalContatoSection = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 772,
                heigth: { xs: '400px', sm: 'auto' },
                width: '100%',
                overflowY: 'auto',
                bgcolor: '#D4D1CB',
                boxShadow: 24,
                p: 4,
                borderRadius: "10px"
            }}>
                <Grid container direction={'column'}>
                    <Grid item xs={12}>
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={{lg: 7, md: 5, sm: 4, xs: 2}}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{
                                    width: '100%',
                                    display: "flex",
                                    height: '100%',
                                    justifyContent: { lg: 'flex-start', md: 'flex-start', sm: 'flex-start', xs: 'center' }
                                }}>
                                    <StyledImg src={imageContato} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Grid container spacing={2} py={5}>
                                    <Grid item xs={12}>
                                        <Typography sx={{
                                            ...fontParams
                                        }} >
                                            Posso Ajudar?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography sx={{
                                            ...fontParamsSub
                                        }}>
                                            Conte conosco para o que precisar
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{
                                            ...fontBodyParams
                                        }}>
                                            Preencha os dados abaixo, fale brevemente como podemos te ajudar e entraremos em contato com você.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Grid container spacing={'16px'}>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="nome"
                                                        label="Nome"
                                                        fullWidth

                                                    />
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="email"
                                                        label="E-mail"
                                                        fullWidth


                                                    />
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="mensagem"
                                                        label="Mensagem"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            label="Eu aceito o termo de ultilização e a politica de privacidade"
                                            control={
                                                <Checkbox
                                                    value=""
                                                    // checked={ }
                                                    // onChange={ }
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </Box>
        </Modal>
    )
}

export default ModalContatoSection