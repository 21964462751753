import * as actionTypes from './actions';

const initialCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
let subTotalValue = 0;
initialCartItems.forEach((item) => (subTotalValue += item.serviceTotalValue));

export const initialState = {
    items: initialCartItems,
    subTotalValue,
    opened: false,
    openItem: -1
};

const cartReducer = (state = initialState, action) => {
    let items;
    let subTotalValue = 0;
    switch (action.type) {
        case actionTypes.SET_CART:
            localStorage.setItem('cartItems', JSON.stringify(action.state.items));
            return { ...action.state };
        case actionTypes.REMOVE_ITEM:
            items = state.items.filter((_, index) => index !== action.index);
            localStorage.setItem('cartItems', JSON.stringify(items));
            items.forEach((item) => (subTotalValue += item.serviceTotalValue));
            return {
                ...state,
                items,
                subTotalValue
            };
        case actionTypes.ADD_ITEM:
            items = [...state.items, action.item];
            localStorage.setItem('cartItems', JSON.stringify(items));
            items.forEach((item) => (subTotalValue += item.serviceTotalValue));
            return {
                ...state,
                items,
                subTotalValue
            };
        case actionTypes.CLEAR_CART:
            localStorage.removeItem('cartItems');
            items = [];
            return {
                ...state,
                items,
                subTotalValue
            };
        case actionTypes.SET_MODAL_ITEM:
            return {
                ...state,
                openItem: action.openItem
            };
        case actionTypes.GET_ITEMS:
            return state;
        case actionTypes.GET_SUBTOTAL:
            return state;
        case actionTypes.SET_CART_MENU:
            return {
                ...state,
                opened: action.opened
            };
        default:
            return state;
    }
};

export default cartReducer;
