import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Modal, Toolbar, useMediaQuery } from '@mui/material';

import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Cart from './Cart';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { IconChevronRight } from '@tabler/icons';
import CustomizedSnackbars from 'ui-component/SnackBar';
import ModalLogin from 'views/auth/authentication/ModalLogin';
import Footer from './Footer';
import WhatsAppButton from './WhatsappButton';
import { useState } from 'react';
import { useEffect } from 'react';
import { GiScrollUnfurled } from 'react-icons/gi';
import bgFooter from 'assets/images/landing/bg-footer.svg'
import ServicesSv from 'services/services.service';
import CustomContainer from 'ui-component/CustomContainer';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
        //[theme.breakpoints.up('md')]: {
        //    marginLeft: -(drawerWidth - 20),
        //    width: `calc(100% - ${drawerWidth}px)`
        //},
        //[theme.breakpoints.down('md')]: {
        //    marginLeft: '20px',
        //    width: `calc(100% - ${drawerWidth}px)`,
        //    padding: '16px'
        //},
        //[theme.breakpoints.down('sm')]: {
        //marginLeft: '10px',
        //width: `calc(100% - ${drawerWidth}px)`
        //padding: '16px'
        //marginRight: '10px'
        //}
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        //marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        //width: `calc(100% - ${drawerWidth}px)`
        //[theme.breakpoints.down('md')]: {
        //    marginLeft: '20px'
        //},
        [theme.breakpoints.down('sm')]: {
            minHeight: `calc(100vw - 200px)`
        }
    })
}));

const MainLayout = () => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const isSmallScrenn = useMediaQuery(theme.breakpoints.down("sm"))
    const [scroll, setScroll] = useState(0)
    const [isScrolled, setIsScrolled] = useState(false)
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const loginMenuOpened = useSelector((state) => state.login.opened);
    const dispatch = useDispatch();

    const handleScroll = () => {
        setScroll(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (scroll > 100) {
            setIsScrolled(true)
        }
        else {
            setIsScrolled(false)
        }
    }, [scroll])
    const ServSv = new ServicesSv();

    const [isLoading, setLoading] = useState(true);
    const categories = useSelector((state) => state.customization.categories);
    //const [categories, setCategories] = useState([]);
    const [svData, setSvData] = useState([]);
    const [filteredSvData, setFilteredSvData] = useState([]);
    const [swiperServices, setSwiperServices] = useState([]);
    const getServicesData = async () => {
        try {
            setLoading(true);
            const res = await ServSv.getServicesData();
            //const categoriesData = await ServSv.getCategories();
            //process.env.NODE_ENV !== 'production' && console.log(categoriesData, 30302);
            //setCategories(categoriesData);
            setSvData(res);
            const swiperSvData = res.filter((service) => service.swiper === 1);
            setSwiperServices(swiperSvData);
            setFilteredSvData(res);
            process.env.NODE_ENV !== 'production' && console.log(3939, res);
        } catch (e) {
            process.env.NODE_ENV !== 'production' && console.log(e);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getServicesData();
        process.env.NODE_ENV !== 'production' && console.log(pathname, 9090);
    }, []);
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const handleClose = () => process.env.NODE_ENV !== 'production' && console.log('teste2');
    //process.env.NODE_ENV !== 'production' && console.log(theme.palette.background, 3030);
    return (
        <>
            <Modal
                open={loginMenuOpened}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowY: 'scroll' }}
            >
                <ModalLogin />
            </Modal>
            <Box sx={{ display: 'flex', overflowX: 'hidden', flexDirection: 'column' }}>
                <CssBaseline />



                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="transparent"
                        elevation={0}
                        sx={{
                            // backgroundImage: {
                            //     xs: theme.palette.background.header,
                            //     sm: isScrolled ? theme.palette.background.header : 'transparent',
                            //     md: isScrolled ? theme.palette.background.header : 'transparent',
                            //     lg: isScrolled ? theme.palette.background.header : 'transparent',
                            // },
                            backgroundColor: pathname !== "/" || isLoading || isSmallScrenn || isScrolled
                                ? theme.palette.background.header
                                : 'transparent',
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',

                        }}
                    >
                        <Toolbar sx={{ justifyContent: { xs: "space-between", sm: "center" }, alignItems: 'center', height: '90px', gap: { xs: 0, sm: "59px" } }} >
                            {/* pl: '12px !important', pr: '10px !important'  */}
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                        </Toolbar>
                    </AppBar>
                <Sidebar drawerOpen={!leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

                <Main theme={theme} open={false} sx={{ background: 'white', flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                    {/*<Breadcrumbs separator={IconChevronRight} icon title rightAlign />*/}
                    <Outlet />
                </Main>
                <Toolbar
                    sx={{
                        justifyContent: 'space-between',
                        pl: '16px !important',
                        pr: '10px !important',
                        flexShrink: 0,
                        backgroundImage: theme.palette.background.footer,
                        // backgroundImage: `url(${bgFooter})`,
                        // backgroundRepeat: "no-repeat",
                        // backgroundSize: 'cover',
                        // backgroundPosition: "center",

                    }}
                >
                    <Footer />
                </Toolbar>
                <CustomizedSnackbars />
                {pathname !== '/checkout' && <Cart />}
                <WhatsAppButton />
            </Box>


        </>
    );
};

export default MainLayout;
