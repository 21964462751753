export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_SNACK_BAR = '@customization/SET_SNACK_BAR';
export const RESET_SNACK_BAR = '@customization/RESET_SNACK_BAR';

export const SET_CATEGORIES = '@customization/SET_CATEGORIES';

export const SET_NAVIGATION = '@customization/SET_NAVIGATION';

export const ADD_ITEM = '@cart/ADD_ITEM';
export const SET_MODAL_ITEM = '@cart/SET_MODAL_ITEM';
export const REMOVE_ITEM = '@cart/REMOVE_ITEM';
export const GET_ITEMS = '@cart/GET_ITEMS';
export const GET_SUBTOTAL = '@cart/GET_SUBTOTAL';
export const SET_CART_MENU = '@cart/SET_CART_MENU';
export const SET_CART = '@cart/SET_CART';
export const CLEAR_CART = '@cart/CLEAR_CART';

export const SET_LOGIN_MENU = '@login/SET_LOGIN_MENU';
export const START_LOGIN_FUNC = '@login/START_LOGIN_FUNC';
export const SET_LOGIN_DATA = '@login/SET_LOGIN_DATA';
export const SET_LOGOUT = '@login/SET_LOGOUT';
export const UPDATE_USER_DATA = '@login/UPDATE_USER_DATA';


export const removeItem = (index) => ({
    type: REMOVE_ITEM,
    index
});
