import { IconHome } from '@tabler/icons';

const icons = { IconHome };

const dashboard = {
    id: 'dashboard',
    title: 'Início',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Início',
            type: 'item',
            url: '/',
            icon: icons.IconHome,
            breadcrumbs: true
        },
        {
            id: 'checkout',
            title: 'Checkout',
            type: 'item',
            url: '/checkout',
            breadcrumbs: true,
            hide: true
        }
    ]
};

export default dashboard;
