import { IconAddressBook, IconHomeEdit, IconLicense, IconShadow, IconGavel, IconCar, IconCertificate, IconState } from '@tabler/icons';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import TaskIcon from '@mui/icons-material/Task';
import { GiBrazil, GiModernCity } from 'react-icons/gi';
import { FaCity } from 'react-icons/fa';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
const IconAddressBookMui = styled(IconAddressBook)({ width: '1em', height: '1em' });
const IconHomeEditMui = styled(IconHomeEdit)({ width: '1em', height: '1em' });
const IconLicenseMui = styled(IconLicense)({ width: '1em', height: '1em' });
const IconGavelMui = styled(IconGavel)({ width: '1em', height: '1em' });
const IconCarMui = styled(IconCar)({ width: '1em', height: '1em' });
const IconCertificateMui = styled(IconCertificate)({});
const GiBrazilMui = styled(GiBrazil)({
    fill: '#fff'
});
const FaCityMui = styled(FaCity)({});
const GiModernCityMui = styled(GiModernCity)({});
const MoreHorizTwoToneIconMui = styled(MoreHorizTwoToneIcon)({});

const CategoriesIcons = [
    {
        'registro-civil': IconAddressBookMui,
        'registro-imoveis': IconHomeEditMui,
        notas: IconLicenseMui,
        protesto: IconGavelMui,
        veiculos: IconCarMui,
        'certidoes-fiscais': IconCertificateMui,
        federais: GiBrazilMui,
        municipal: FaCityMui,
        estaduais: GiModernCityMui,
        outros: MoreHorizTwoToneIconMui,
        pesquisa: SearchIcon,
        escrituras: IconLicenseMui,
        'certificados-digitais': SettingsRemoteIcon,
        rtd: TaskIcon


    },
    {
        'registro-civil': IconAddressBook,
        'registro-imoveis': IconHomeEdit,
        notas: IconLicense,
        protesto: IconGavel,
        veiculos: IconCar,
        'certidoes-fiscais': IconCertificate,
        federais: GiBrazil,
        municipal: FaCity,
        estaduais: GiModernCity,
        outros: MoreHorizTwoToneIcon,
        pesquisa: SearchIcon,
        escrituras: IconLicense,
        'certificados-digitais': SettingsRemoteIcon,
         rtd: TaskIcon
    }
];

const getCategoryIconByURL = (url, menu = 0) => CategoriesIcons[menu][url];

export default getCategoryIconByURL;
