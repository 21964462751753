import ServiceBase from './service.base';
import { transformString } from 'utils/utils';

export default class ServicesService extends ServiceBase {
    constructor() {
        super('client');
    }
    async create(payload) {
        const data = await this.post(`/clients`, payload);
        return data;
    }

    async edit(payload) {
        payload.client.birthDate = payload.client?.birthDate.split('T')[0];
        payload.refs?.forEach((ref) => {
            ref.birthDate = ref.birthDate.split('T')[0];
        });
        const data = await this.post(`/clients/${payload.id}`, payload);
        return data;
    }

    getFreightCalculation(cep) {
        return this.get(`/freight/calculate/${cep}`);
    }
    getServicesData() {
        return this.get(`/services`);
    }
    getCategories() {
        return this.get(`/categories`);
    }
    getServiceInfo(id) {
        return this.get(`/services/${id}`);
    }
    getServiceSteps(id) {
        return this.get(`/steps/service/${id}`);
    }
    getAllStates() {
        return this.get(`/federatives-units`);
    }
    getCitiesByState(state) {
        return this.get(`/cities/${state}`);
    }
    getCityById(cityId) {
        return this.get(`/city/${cityId}`);
    }
    getRegistryOfficesByCityId(cityId) {
        return this.get(`/registry-offices/${cityId}`);
    }
    getROByUfAndCity(uf, city, assignmentId = 12) {
        return this.get(`/registry-offices?state=${uf}&city=${city}&assignmentId=${assignmentId}`);
    }
    async getClientByCpf(cpf) {
        process.env.NODE_ENV !== 'production' && console.log(9090);
        const data = await this.get(`/clients/${cpf}`);
        data.client.birthDate = data.client.birthDate.split('T')[0];
        process.env.NODE_ENV !== 'production' && console.log(data, 9091);
        return data;
    }
    addNewUserAddress(payload) {
        return this.post(`/address`, payload);
    }
    removeUserAddress(id) {
        return this.delete(`/address/${id}`);
    }
    getUserAddress() {
        return this.get(`/address`);
    }
    createPayment(payload) {
        return this.post(`/orders`, payload);
    }
    getOrderCheckout(orderId) {
        return this.get(`/orders/${orderId}/checkout`);
    }
    getOrderStatus() {
        return this.get(`/orders`);
    }
    getOrderDetails(orderId) {
        return this.get(`/orders/${orderId}`);
    }
}
