import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Box, Button, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LOGIN_MENU } from 'store/actions';
import { LoginTwoTone, PersonAddAlt1 } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const login = useSelector((state) => state.login);
    const theme = useTheme();
    const dispatch = useDispatch();
    const handleOpenModalLogin = (mode) => {
        dispatch({ type: SET_LOGIN_MENU, opened: true, mode: mode || 'login' });
        //setOpen(false);
    };
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            {/*<Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection variant="blueYellow" />
                </Box>
            </Box>*/}
            {/*<BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>*/}
            <Box sx={{ px: 2 }}>
                <AnimateButton sx={{ display: { xs: login.auth && 'none', sm: 'none' } }}>
                    {/*<Button
                disabled={false}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                to="auth/login"
                component={Link}
                sx={{ mr: 1 }}
                endIcon={<LoginTwoTone />}
            >
                Login
            </Button>*/}
                    <Button
                        disabled={false}
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenModalLogin('register')}
                        sx={{ display: { sm: 'none', xs: login.auth ? 'none' : 'inline-flex' }, mr: 1, mt: 2 }}
                        endIcon={<PersonAddAlt1 />}
                    >
                        Cadastrar
                    </Button>
                </AnimateButton>
                <AnimateButton sx={{ display: { xs: login.auth && 'none', sm: 'none' }, mt: 2 }}>
                    {/*<Button
                disabled={false}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="secondary"
                to="auth/login"
                component={Link}
                sx={{ mr: 1 }}
                endIcon={<LoginTwoTone />}
            >
                Login
            </Button>*/}
                    <Button
                        disabled={false}
                        fullWidth
                        size="small"
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleOpenModalLogin('login')}
                        sx={{ display: { sm: 'none', xs: login.auth ? 'none' : 'inline-flex' }, mr: 1, mt: 2 }}
                        endIcon={<LoginTwoTone />}
                    >
                        Login
                    </Button>
                </AnimateButton>
                <MenuList />
            </Box>
            {/*</MobileView>*/}
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{
                //flexShrink: { md: 0 },
                width: 'auto'
            }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant={'temporary'}
                anchor="right"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiModal-backdrop': {
                        //top: theme.mainHeader.height
                    },
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default, //'#0f458d',
                        color: theme.palette.text.primary,
                        borderRight: 'none'
                        //top: theme.mainHeader.height
                        //[theme.breakpoints.up('md')]: {
                        //    top: '88px'
                        //}
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
