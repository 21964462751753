import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Stack, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PerfectScrollbar from 'react-perfect-scrollbar';

//const headerSX = {
//    '& .MuiCardHeader-action': { mr: 0 }
//};

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = { paddingLeft: 0, paddingRight: 0 },
            title,
            handleClose,
            headerSX,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        //const TitleText = ;
        const HeaderTitle = handleClose ? (
            <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems={'center'}>
                <Typography variant="h3">{title}</Typography>
                <IconButton onClick={handleClose} sx={{ '&:hover': { color: theme.palette.primary.dark, backgroundColor: 'transparent' } }}>
                    <CloseRoundedIcon sx={{ fontSize: 24 }} />
                </IconButton>
            </Stack>
        ) : (
            <Typography variant="h3">{title}</Typography>
        );

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 25,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {title && <CardHeader sx={headerSX} title={HeaderTitle} action={secondary} />}

                {title && <Divider />}

                {content ? (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                ) : (
                    children
                )}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
