import { Suspense } from 'react';

import Loader from './Loader';
const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<Loader variant={1} />}>
            <Component {...props} />
        </Suspense>
    );

export default Loadable;
