import { Link, Outlet, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import AuthWrapper1 from './AuthWrapper1';
import AuthCardWrapper from './AuthCardWrapper';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { styled } from '@mui/material/styles';

const AuthLayout = () => {
    const location = useLocation();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    process.env.NODE_ENV !== 'production' && console.log(location, 383890);
    process.env.NODE_ENV !== 'production' && console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Stack alignItems="center" justifyContent="center" spacing={0}>
                                            <Link to="#">
                                                <Logo />
                                            </Link>
                                            <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                                Mais que um despachante online.
                                            </Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Outlet />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            {location.pathname.includes('sign-up') ? (
                                                <Typography
                                                    component={Link}
                                                    to="/auth/login"
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'none' }}
                                                >
                                                    Já possui uma conta?
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    component={Link}
                                                    to="/auth/sign-up"
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'none' }}
                                                >
                                                    Não possui uma conta?
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default AuthLayout;
