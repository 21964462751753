import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = () => {
    const style = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        color: 'white',
        '&:hover': {
            backgroundColor: '#00E676'
        },
        zIndex: 1000
    };

    const handleClick = () => {
        window.open(
            'https://api.whatsapp.com/send?phone=558592858796&text=Olá! Quero mais informações sobre os serviços da BrDocs.',
            '_blank'
        );
    };

    return (
        <Button variant="contained" style={style} onClick={handleClick} startIcon={<WhatsAppIcon />}>
            Fale conosco
        </Button>
    );
};

export default WhatsAppButton;
