import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateCNPJ, validateCPF } from 'utils/utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useTheme } from '@mui/material/styles';
import InputMask from 'react-input-mask';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Signup, googleLogin } from 'services/auth.service';
import { SET_LOGIN_DATA, SET_LOGIN_MENU, START_LOGIN_FUNC } from 'store/actions';
import { extractDataFromSession } from 'utils/auth';
import ClientService from 'services/client.service';

const cpfMask = '999.999.999-99';
const cnpjMask = '99.999.999/9999-99';

const BRDocsRegister = ({ modal, setMode, ...others }) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const loginSel = useSelector((state) => state.login);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [showPassword, setShowPassword] = useState(false);

    const [cpfCnpjMask, setCpfCnpjMask] = useState(cpfMask);

    const closeModal = () => dispatch({ type: SET_LOGIN_MENU, opened: false });
    const ClientSv = new ClientService();
    const handleSuccess = (data) => {
        sessionStorage.setItem('accessToken', data.accessToken);
        sessionStorage.setItem('refreshToken', data.refreshToken);
        process.env.NODE_ENV !== 'production' && console.log(data);
        if (!modal) {
            const redirectUrl = sessionStorage.getItem('redirectUrl');
            if (redirectUrl) {
                sessionStorage.removeItem('redirectUrl');
                window.location = redirectUrl;
            } else {
                window.location = '/';
            }
        } else {
            closeModal();
            dispatch({ type: SET_LOGIN_DATA });
            loginSel.func && loginSel.func();
        }
    };
    const handleLoginErr = (err) => {
        process.env.NODE_ENV !== 'production' && console.error(err);
    };

    const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            //const { data } = await Signup(values);
            const data = await ClientSv.editUserData(values);
            handleSuccess(data);

            setStatus({ success: true });
            setSubmitting(false);
        } catch (err) {
            handleLoginErr(err);

            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Atualize suas informações para continuar</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    nome: extractDataFromSession().nome,
                    documento: '',
                    telefone: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    nome: Yup.string().max(255).required('Nome é um campo obrigatório!'),
                    telefone: Yup.string()
                        .required('Telefone é um campo obrigatório')
                        .test('telefone', 'Telefone inválido', (value) => {
                            return value?.replace(/[^\d]/g, '').length >= 10;
                        }), //),
                    documento: Yup.string()
                        .required('CPF/CNPJ é um campo obrigatório!')
                        .test('cpf-cnpj', 'CPF/CNPJ inválido', (value) => {
                            return validateCNPJ(value) || validateCPF(value);
                        })
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-name-register">Nome</InputLabel>
                                    <OutlinedInput
                                        id="outlined-name"
                                        type="text"
                                        value={values.nome}
                                        name="nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome && errors.nome && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.documento && errors.documento)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-doc-register">CPF/CNPJ</InputLabel>
                                    <InputMask
                                        mask={cpfCnpjMask}
                                        maskPlaceholder=""
                                        value={values.documento}
                                        id="outlined-adornment-doc-register"
                                        type="text"
                                        onChange={(e) => {
                                            e.target.value?.replace(/\D/g, '').length < 11
                                                ? setCpfCnpjMask(cpfMask)
                                                : setCpfCnpjMask(cnpjMask);
                                            handleChange(e);
                                        }}
                                        onBlur={(e) => {
                                            values.documento.replace(/\D/g, '').length === 11 && setCpfCnpjMask(cpfMask);
                                            handleBlur(e);
                                        }}
                                        name="documento"
                                    >
                                        {(inputProps) => <OutlinedInput {...inputProps} />}
                                    </InputMask>

                                    {touched.documento && errors.documento && (
                                        <FormHelperText error id="standard-weight-helper-doc--register">
                                            {errors.documento}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.telefone && errors.telefone)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-phone-register">Telefone</InputLabel>
                                    <InputMask
                                        id="outlined-phone"
                                        mask={'(99) 9 9999-9999'}
                                        maskPlaceholder=""
                                        value={values.telefone}
                                        name="telefone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type={'text'}
                                    >
                                        {(inputProps) => <OutlinedInput {...inputProps} />}
                                    </InputMask>
                                    {touched.telefone && errors.telefone && (
                                        <FormHelperText error id="standard-weight-helper-phone">
                                            {errors.telefone}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Atualizar informações
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default BRDocsRegister;
