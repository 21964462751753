import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/AuthLayout';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Services = Loadable(lazy(() => import('views/services')));
const Categories = Loadable(lazy(() => import('views/categories')));
const Checkout = Loadable(lazy(() => import('views/checkout')));
const AuthLogin = Loadable(lazy(() => import('views/auth/auth-forms/AuthLogin')));
const AuthRegister = Loadable(lazy(() => import('views/auth/auth-forms/AuthRegister')));
const TermsAndConditions = Loadable(lazy(() => import('views/terms/TermsAndConditions')));
const PrivacyPolicy = Loadable(lazy(() => import('views/terms/PrivacyPolicy')));
const Profile = Loadable(lazy(() => import('views/profile')));

const MainRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        {
            path: 'checkout',
            element: <Checkout />
        },
        {
            path: 'categorias',
            element: <Categories />,
            children: [
                {
                    path: ':id',
                    element: <Categories />
                }
            ]
        },
        {
            path: 'servicos',
            children: [
                {
                    path: ':id',
                    element: <Services />
                }
            ]
        },
        {
            path: 'auth',
            element: <AuthLayout />,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin />
                },
                {
                    path: 'sign-up',
                    element: <AuthRegister />
                }
            ]
        },
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'termos',
            element: <TermsAndConditions />
        },
        {
            path: 'politica-privacidade',
            element: <PrivacyPolicy />
        },
        {
            path: 'perfil',
            element: <Profile />
        }
        //{
        //    path: 'dashboard',
        //    children: [
        //        {
        //            path: ':id',
        //            element: <DashboardDefault />
        //        }
        //    ]
        //}
    ]
};

export default MainRoutes;
