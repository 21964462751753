import { IconKey } from '@tabler/icons';

const icons = {
    IconKey
};

const services = {
    id: 'services',
    title: 'Serviços',
    type: 'group',
    children: [
        {
            id: 'authentication',
            title: 'Authentication',
            type: 'collapse',
            icon: icons.IconKey,
            breadcrumbs: false,

            children: [
                {
                    id: 'login',
                    title: 'Login',
                    type: 'item',
                    url: '/auth/login',
                    target: true,
                    breadcrumbs: false
                },
                {
                    id: 'register',
                    title: 'Register',
                    type: 'item',
                    url: '/auth/sign-up',
                    target: true,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default services;
