import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmptyCart from 'assets/icons/cart-empty-brdocs.svg';
import { useTheme } from '@mui/material/styles';
import {
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slider,
    Tooltip,
    Stack,
    Typography,
    Button,
    Divider,
    Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { REMOVE_ITEM } from 'store/actions';
import { gridSpacing } from 'store/constant';
import CartItems from './components/cartItems';
import { Link, NavLink } from 'react-router-dom';

const Cart = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        process.env.NODE_ENV !== 'production' && console.log(cart);
        setOpen(!open);
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 3,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px'
        }
    }));

    const StyledAddItemBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 3,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px'
        }
    }));

    return (
        <>
            <Tooltip title="Cart">
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="circular"
                    color="primary"
                    sx={{
                        borderRadius: '4px',
                        top: '35%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial
                    }}
                >
                    <IconButton color="inherit" size="large" disableRipple>
                        <StyledBadge badgeContent={cart.items.length} color={cart.items.length > 0 ? 'success' : 'error'} showZero>
                            <ShoppingCartTwoToneIcon />
                        </StyledBadge>
                    </IconButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 350
                    }
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3} sx={{ py: 1, pl: 2, pr: 3 }}>
                    <IconButton
                        onClick={handleToggle}
                        sx={{ '&:hover': { color: theme.palette.primary.dark, backgroundColor: 'transparent' } }}
                    >
                        <CloseRoundedIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <Typography variant="h4" color="gray">
                        MEU CARRINHO
                    </Typography>
                    <StyledBadge badgeContent={cart.items.length} color={cart.items.length > 0 ? 'success' : 'error'} showZero>
                        <ShoppingCartTwoToneIcon />
                    </StyledBadge>
                </Stack>
                <Divider />
                {cart.items.length === 0 ? (
                    <Stack direction="column" justifyContent={'center'} height={'100%'} alignItems={'center'}>
                        <img src={EmptyCart} alt="Seu carrinho está vazio" height={'auto'} width={'50%'} />
                        <Typography variant="h4" color="gray">
                            Seu carrinho está vazio
                        </Typography>
                    </Stack>
                ) : (
                    <PerfectScrollbar component="div">
                        <Grid container spacing={2} sx={{ p: 3 }} direction="column">
                            <CartItems />
                        </Grid>
                    </PerfectScrollbar>
                )}
                <Divider />
                <Stack direction="column" sx={{ p: 2 }} spacing={1}>
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Typography variant="h4" color="gray">
                            Subtotal
                        </Typography>
                        <Typography variant="h4" color="gray">
                            R${' '}
                            {parseFloat(cart.subTotalValue).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Typography>
                    </Stack>
                    <Button
                        disabled={cart.items.length === 0}
                        component={Link}
                        to="/checkout"
                        onClick={handleToggle}
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        FINALIZAR PEDIDO
                    </Button>
                </Stack>
            </Drawer>
        </>
    );
};

export default Cart;
